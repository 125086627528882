import React from 'react'

type Props = {
  pathname: string
}

const Breadcrump = ({ pathname }: Props): React.ReactElement => {
  const items = pathname.split('/')
  items[0] = 'Home'

  return (
    <div id="breadcrumb" className="section">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <ul className="breadcrumb-tree">
              {items.map((item, index) => (
                <li
                  key={index}
                  className={index + 1 === items.length ? 'active' : undefined}
                >
                  <a href={getPath(items, index)}>{item}</a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

function getPath(items: string[], index: number): string {
  items[0] = ''
  return items.slice(0, index + 1).join('/')
}

export default Breadcrump
