import React from 'react'
import { Link } from 'gatsby'
import { Category } from '../../types/types'
import './style.css'
import './car-card.css'

type Props = {
  hit: {
    id: string
    name: string
    categories: Category[]
    priceInEUR: string
    gallery: any
    description: string
  }
}

const HitComp = ({ hit }: Props): React.ReactElement => {
  const { id, name, categories, priceInEUR, gallery, description } = hit

  console.log(hit)

  return (
    <Link to={`/p/cars/${id}`}>
      <div className="product">
        <div className="product-img">
          <img
            src={`https://demo.pimcore.fun${gallery[0].image.fullpath}`}
            alt=""
          />
        </div>
        <div className="product-body">
          <div className="product-categories">
            {categories.map((category) => (
              <p key={category.id} className="product-category">
                {category.name}
              </p>
            ))}
          </div>
          <h3 className="product-name">
            <a href="#">{name}</a>
          </h3>
          <h4 className="product-price">{priceInEUR}</h4>
          <div className="product-rating">
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>
            <i className="fa fa-star"></i>
          </div>
          <div className="product-btns">
            <button className="add-to-wishlist">
              <i className="fa fa-heart-o"></i>
              <span className="tooltipp">add to wishlist</span>
            </button>
            <button className="add-to-compare">
              <i className="fa fa-exchange"></i>
              <span className="tooltipp">add to compare</span>
            </button>
            <button className="quick-view">
              <i className="fa fa-eye"></i>
              <span className="tooltipp">quick view</span>
            </button>
          </div>
        </div>
        <div className="add-to-cart">
          <button
            className="snipcart-add-item add-to-cart-btn"
            data-item-id={id}
            data-item-image={`https://demo.pimcore.fun${gallery[0].image.fullpath}`}
            data-item-price={priceInEUR}
            data-item-description={
              description?.replace(/(<([^>]+)>)/gi, '').substr(0, 100) + '...'
            }
            data-item-url={`p/cars/${id}`}
            data-item-name={name}
          >
            <i className="fa fa-shopping-cart"></i> add to cart
          </button>
        </div>
      </div>
    </Link>
  )
}

export default HitComp
