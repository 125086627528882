import React from 'react'

import Breadcrump from '../components/Breadcrump'
import Store from '../components/Store'
import { graphql, PageProps } from 'gatsby'
import { CategoryPageQuery } from '../../types/graphql-types'

const CategoryPage = ({
  data,
  location,
}: PageProps<CategoryPageQuery>): React.ReactElement => {
  return (
    <>
      <Breadcrump pathname={location.pathname} />
      <Store name={data.pimcore.getCategory.name} />
    </>
  )
}

export const pageQuery = graphql`
  query CategoryPage($id: Int!) {
    pimcore {
      getCategory(id: $id) {
        name
      }
    }
  }
`

export default CategoryPage
