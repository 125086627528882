import React from 'react'

import RefinementListSearch from '../algolia-features/refinement-list'

type Props = {
  name: string
}

const Store = ({ name }: Props): React.ReactElement => {
  return (
    <div className="section">
      <div className="container">
        <RefinementListSearch defaultRefinement={name ? [name] : null} />
      </div>
    </div>
  )
}

export default Store
