import React from 'react'
import {
  Hits,
  connectStateResults,
  ClearRefinements,
  RefinementList,
  Pagination,
  Configure,
  RangeInput,
  SortBy,
} from 'react-instantsearch-dom'
import HitComp from '../HitComp'

type Props = {
  defaultRefinement: string[] | null
}

const Results = connectStateResults(
  ({ searchState: state, searchResults: res, children }) =>
    res && res.nbHits > 0 ? <>{children}</> : <>No results for ${state.query}</>
)

const Stats = connectStateResults(({ searchResults: res }) =>
  res && res.nbHits > 0 ? (
    <>{`${res.nbHits} result${res.nbHits > 1 ? `s` : ``}`}</>
  ) : null
)

const RefinementListSearch = ({
  defaultRefinement,
}: Props): React.ReactElement => {
  return (
    <div className="row">
      <div className="aside col-md-3">
        <Configure hitsPerPage={9} />
        <ClearRefinements />
        <Stats />
        {/* <SortBy
          defaultRefinement="instant_search"
          items={[
            { value: 'instant_search', label: 'Featured' },
            { value: 'instant_search_price_asc', label: 'Price asc.' },
            { value: 'instant_search_price_desc', label: 'Price desc.' },
          ]}
        /> */}
        <h3 className="aside-title">Categories</h3>
        <div className="checkbox-filter">
          <RefinementList
            attribute="categories.name"
            defaultRefinement={defaultRefinement}
          />
        </div>
        <h3 className="aside-title">Refine by country</h3>
        <div className="checkbox-filter">
          <RefinementList attribute="country" />
        </div>
        <h3 className="aside-title">Price</h3>
        <div>
          <RangeInput attribute="priceInEUR" />
        </div>
      </div>
      <div id="store" className="col-md-9">
        <Results>
          <Hits hitComponent={HitComp} />
        </Results>
        <Pagination />
      </div>
    </div>
  )
}
export default RefinementListSearch
